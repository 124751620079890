import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Web3 from 'web3';

import {StoreState} from '../../util/types';
import {isEthAddressValid} from '../../util/validation';
import {FetchStatus} from '../../util/enums';
import {
  formatEthereumAddress,
  getTokenURIData,
  getFallbackTokenMetadata,
} from '../../util/helpers';
import {useIsDefaultChain} from '../../hooks';
import {useWeb3Modal} from '../web3/Web3ModalManager';
import {ETHERSCAN_URLS} from '../../util/config';
import Wrap from '../common/Wrap';
import FadeIn from '../common/FadeIn';
import LoaderLarge from '../feedback/LoaderLarge';
import NFT from '../../smart-contracts/NFT.json';

import m from '../../assets/scss/modules/memberdetails.module.scss';
import mi from '../../assets/scss/modules/memberinfo.module.scss';
import c from '../../assets/scss/modules/contract.module.scss';

type NFTDetailsState = {
  address: string;
  tokenId: string;
  name: string;
  symbol: string;
  owner: string;
  tokenURIName: string;
  description: string;
  image_url?: string;
  animation_url?: string;
  image_data?: string;
};

const INITIAL_STATE: NFTDetailsState = {
  address: '',
  tokenId: '',
  name: '',
  symbol: '',
  owner: '',
  tokenURIName: '',
  description: '',
  image_url: undefined,
  animation_url: undefined,
  image_data: undefined,
};

const PLACEHOLDER = '\u00A0\u2013'; /* nbsp ndash */

export default function NFTDetails() {
  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const defaultChain = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  /**
   * Hooks
   */

  const {isDefaultChain, defaultChainError} = useIsDefaultChain();
  const {connected, account} = useWeb3Modal();

  /**
   * State
   */

  const [nftDetails, setNftDetails] = useState<NFTDetailsState>(INITIAL_STATE);
  const [nftDetailsFetchStatus, setNftDetailsFetchStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);
  const [error, setError] = useState<Error>();
  const [imageDataURL, setImageDataURL] = useState<string>();

  /**
   * Variables
   */

  const {ethereumAddress: ethereumAddressRouterParam = '' as string} =
    useParams<{ethereumAddress: string}>();
  const {tokenId: tokenIdRouterParam = '' as string} = useParams<{
    tokenId: string;
  }>();
  const isConnected = connected && account;
  const isTokenAddressValid = isEthAddressValid(ethereumAddressRouterParam);

  /**
   * Cached callbacks
   */

  const getNftDetailsCached = useCallback(getNftDetails, [
    isConnected,
    isDefaultChain,
    isTokenAddressValid,
    web3Instance,
    ethereumAddressRouterParam,
    tokenIdRouterParam,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    setNftDetailsFetchStatus(FetchStatus.PENDING);
    getNftDetailsCached()
      .then((details) => {
        details &&
          setNftDetails({
            address: details.contractAddress,
            name: details.name,
            symbol: details.symbol,
            tokenId: details.tokenId,
            owner: details.owner,
            tokenURIName: details.tokenURIName,
            description: details.description,
            image_url: details.image_url,
            animation_url: details.animation_url,
            image_data: details.image_data,
          });

        setNftDetailsFetchStatus(FetchStatus.FULFILLED);
      })
      .catch((error) => {
        setNftDetailsFetchStatus(FetchStatus.REJECTED);
        setError(error);
      });
  }, [getNftDetailsCached]);

  useEffect(
    () => () => {
      // Make sure to revoke the data URIs to avoid memory leaks
      imageDataURL && URL.revokeObjectURL(imageDataURL);
    },
    [imageDataURL]
  );

  /**
   * Functions
   */

  // If connected user and page URL includes valid Ethereum address, attempt to
  // connect to NFT smart contract and read data.
  async function getNftDetails() {
    try {
      if (
        !isConnected ||
        !isDefaultChain ||
        !isTokenAddressValid ||
        !web3Instance
      )
        return;

      const nftContract: Record<string, any> = NFT;
      // custom error thrown below if this fails; cursory way of detecting if
      // Ethereum address is an ERC721 type contract
      const instance = new web3Instance.eth.Contract(
        nftContract.abi,
        ethereumAddressRouterParam
      );
      const {methods, options} = instance;
      const contractAddress = options.address;
      const name: string = await methods.name().call();
      const symbol: string = await methods.symbol().call();
      const owner: string = await methods
        .ownerOf(Number(tokenIdRouterParam))
        .call();
      const tokenURI: string = await methods
        .tokenURI(Number(tokenIdRouterParam))
        .call();
      // Assumes ERC721 metadata standard (see
      // https://docs.opensea.io/docs/metadata-standards#section-metadata-structure).
      const {
        name: tokenURIName,
        description,
        image: image_url,
        animation_url,
        image_data,
      } = await getTokenURIData(tokenURI);

      if (image_data) {
        const svg = image_data;
        const blob = new Blob([svg], {type: 'image/svg+xml'});
        setImageDataURL(URL.createObjectURL(blob));
      }

      return {
        contractAddress,
        name,
        symbol,
        tokenId: tokenIdRouterParam,
        owner,
        tokenURIName,
        description,
        image_url,
        animation_url,
        image_data,
      };
    } catch (error) {
      // If a token does not use ERC721 contract and metadata standards it will
      // likely fail in the process above to fetch and parse the metadata. In
      // that case, we can try to retrieve the data using the OpenSea API.

      try {
        const {
          image_original_url: image_url,
          animation_original_url: animation_url,
          image_data,
          description,
          name: tokenURIName,
          asset_contract,
          owner,
        } = await getFallbackTokenMetadata(
          ethereumAddressRouterParam,
          tokenIdRouterParam
        );
        const {name, symbol} = asset_contract as any;
        const {address} = owner as any;

        if (image_data) {
          const svg = image_data;
          const blob = new Blob([svg], {type: 'image/svg+xml'});
          setImageDataURL(URL.createObjectURL(blob));
        }

        return {
          contractAddress: Web3.utils.toChecksumAddress(
            ethereumAddressRouterParam
          ),
          name,
          symbol,
          tokenId: tokenIdRouterParam,
          owner: Web3.utils.toChecksumAddress(address),
          tokenURIName,
          description,
          image_url,
          animation_url,
          image_data,
        };
      } catch (error) {
        throw new Error(
          'Token does not exist. Please check that the page URL includes a valid ERC721 address and token ID.'
        );
      }
    }
  }

  function renderNFTInfoColumn(): JSX.Element {
    return (
      <div className={mi.wrap}>
        <div
          className={`${mi['member-details-container']} org-member-details-container`}>
          {/* <div
            className={`${mi['member-section-title']} org-member-section-title`}>
            Token Info
          </div> */}

          {nftDetails.tokenURIName && (
            <div
              className={`${c['contract-info-item']} org-contract-info-item`}>
              <span>Name</span>
              <span>{nftDetails.tokenURIName}</span>
            </div>
          )}

          {nftDetails.description && (
            <div
              className={`${c['contract-info-item']} org-contract-info-item`}>
              <span>Description</span>
              <span>{nftDetails.description}</span>
            </div>
          )}

          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>Contract Address</span>
            {nftDetails.address ? (
              <a
                href={`${
                  ETHERSCAN_URLS[defaultChain]
                }/address/${nftDetails.address.toLowerCase()}`}
                target="_blank"
                rel="noopener noreferrer">
                {formatEthereumAddress(nftDetails.address, 10)}
              </a>
            ) : (
              <span>{PLACEHOLDER}</span>
            )}
          </div>

          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>Token ID</span>
            <span>{nftDetails.tokenId}</span>
          </div>

          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>Owner</span>
            {nftDetails.owner ? (
              <a
                href={`${
                  ETHERSCAN_URLS[defaultChain]
                }/address/${nftDetails.owner.toLowerCase()}`}
                target="_blank"
                rel="noopener noreferrer">
                {formatEthereumAddress(nftDetails.owner, 10)}
              </a>
            ) : (
              <span>{PLACEHOLDER}</span>
            )}
          </div>

          {nftDetails.symbol && (
            <div
              className={`${c['contract-info-item']} org-contract-info-item`}>
              <span>Token Symbol</span>
              <span>{nftDetails.symbol}</span>
            </div>
          )}

          {nftDetails.name && (
            <div
              className={`${c['contract-info-item']} org-contract-info-item`}>
              <span>Token Name</span>
              <span>{nftDetails.name}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderNFTPreviewColumn(): JSX.Element {
    // detect video file first to display
    if (nftDetails.animation_url) {
      const srcUrl = nftDetails.animation_url.startsWith('ipfs://')
        ? nftDetails.animation_url.replace('ipfs://', 'https://ipfs.io/')
        : nftDetails.animation_url;

      return (
        <div className={m['preview-file-container']}>
          <div className={m['thumb']}>
            <video className={m['preview-file']} controls autoPlay>
              <source src={srcUrl} />
            </video>
          </div>
        </div>
      );
    }

    // then raw image data (e.g., svg)
    if (nftDetails.image_data) {
      return (
        <div className={m['preview-file-container']}>
          <div className={m['thumb']}>
            <img
              className={m['preview-file']}
              src={imageDataURL}
              alt="preview"
            />
          </div>
        </div>
      );
    }

    // then image file
    if (nftDetails.image_url) {
      const srcUrl = nftDetails.image_url.startsWith('ipfs://')
        ? nftDetails.image_url.replace('ipfs://', 'https://ipfs.io/')
        : nftDetails.image_url;

      return (
        <div className={m['preview-file-container']}>
          <div className={m['thumb']}>
            <img className={m['preview-file']} src={srcUrl} alt="preview" />
          </div>
        </div>
      );
    }

    return (
      <div className={m['preview-file-container']}>
        <div className={m['no-image-available']}>
          <small>No image available</small>
        </div>
      </div>
    );
  }

  function renderErrorMessage() {
    // Render invalid token address (detected from router param)
    if (!isTokenAddressValid) {
      return 'Invalid token address. Please check that the page URL includes a valid Ethereum address.';
    }

    // Render wallet auth message if user is not connected
    if (!isConnected) {
      return 'Connect your wallet to view the NFT.';
    }

    // Render wrong network message if user is on wrong network
    if (!isDefaultChain) {
      return defaultChainError;
    }

    // Render error returned while fetching token data
    if (error) {
      return error.message;
    }
  }

  /**
   * Render
   */

  // Render invalid token address (detected from router param)
  if (!isTokenAddressValid || !isDefaultChain || !isConnected || error) {
    return (
      <RenderWrapper>
        <div>
          <section
            className={`${m['member-details-wrapper']} org-member-details-wrapper`}>
            <p
              className={`${m.error} org-error-message org-notification info`}
              style={{paddingTop: 0, maxWidth: '90%', margin: '1em auto'}}>
              {renderErrorMessage()}
            </p>
          </section>
        </div>
      </RenderWrapper>
    );
  }

  // Render loading status if the token data is still being fetched
  if (nftDetailsFetchStatus === FetchStatus.PENDING) {
    return (
      <RenderWrapper>
        <div style={{width: '3rem', margin: '2rem auto 0'}}>
          <LoaderLarge />
        </div>
        <p className="text-center">Loading NFT&hellip;</p>
      </RenderWrapper>
    );
  }

  return (
    <RenderWrapper
      title={nftDetails.tokenURIName}
      description={nftDetails.description}>
      <div>
        <section
          className={`${m['member-details-wrapper']} org-member-details-wrapper`}>
          <aside className={m['member-column']}>{renderNFTInfoColumn()}</aside>
          <section className={m['voting-history-column']}>
            {renderNFTPreviewColumn()}
          </section>
        </section>
      </div>
    </RenderWrapper>
  );
}

type RenderWrapperProps = {
  children: React.PropsWithChildren<any>;
  title?: string;
  description?: string;
};

function RenderWrapper(props: RenderWrapperProps): JSX.Element {
  const {children, title, description} = props;

  return (
    <>
      {/** REACT HELMET */}
      <Helmet>
        <title>{title || 'NFT'}</title>
        <meta name="description" content={description || 'NFT Details'} />
      </Helmet>

      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">NFT Details</h2>
          </div>

          {/* RENDER CHILDREN */}
          {children}
        </FadeIn>
      </Wrap>
    </>
  );
}
