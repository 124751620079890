import React from 'react';

import LogoNFT from './LogoNFT';

import h from '../../assets/scss/modules/header.module.scss';

interface LogoProps {
  size?: 'small' | 'medium' | 'large' | '';
}

function RenderLogo(props: React.PropsWithChildren<any>) {
  return props.children;
}

/**
 * ModalLogo
 * This component is used for modal menu
 */
export function ModalLogo() {
  return (
    <div
      className={`${h.title} org-modal-menu-title`}
      style={{textAlign: 'center', padding: '0'}}>
      <OrgLogo size={'small'} />
    </div>
  );
}

/**
 * LeftLogo
 * This component is used for main pages (exc splash/contribute)
 */
export function LeftLogo() {
  return (
    <div className={`${h.title} org-header-title`}>
      <OrgLogo size={'medium'} />
    </div>
  );
}

/**
 * CenterLogo
 * This component is used for splash and contribute pages
 */
export function CenterLogo() {
  return (
    <div className={`${h['openlaw-logo']} org-openlaw-logo`}>
      <div
        className={`${h.title} ${h['title--center']} org-header-title--center`}>
        <RenderLogo>
          <OrgLogo size={'large'} />
        </RenderLogo>

        <h2 className={`${h['tag-line']} org-tag-line`}>NFT Minter</h2>
      </div>
    </div>
  );
}

/**
 * Default export OrgLogo
 * @param props size "small|medium|large|<empty>"
 */
export default function OrgLogo(props: LogoProps) {
  function renderOrgLogo() {
    return <LogoNFT {...props} />;
  }

  return <>{renderOrgLogo()}</>;
}
