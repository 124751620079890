import React, {useState} from 'react';
import {Transition} from 'react-transition-group';
import ReactModal from 'react-modal';
import Media from 'react-media';
import {NavLink, useLocation} from 'react-router-dom';

import Web3ModalButton from './web3/Web3ModalButton';
import {ModalLogo} from './logo';
import TimesSVG from '../assets/svg/TimesSVG';
import HamburgerSVG from '../assets/svg/HamburgerSVG';

import s from '../assets/scss/modules/nav.module.scss';
import m from '../assets/scss/modules/modal.module.scss';

// see: http://reactcommunity.org/react-transition-group/transition
const duration = 200;

const defaultStyle = {
  transition: '0.1s',
};

const transitionOpeningStyles: Record<string, any> = {
  entering: {right: '-300px'},
  entered: {right: 0},
  exiting: {right: 0, opacity: 0},
  exited: {right: '-300px', opacity: 0},
};

const transitionClosingStyles: Record<string, any> = {
  entering: {right: 0, opacity: 1},
  entered: {right: '-300px', opacity: 1},
  exiting: {right: '-300px', opacity: 1},
  exited: {right: 0, opacity: 1},
};

export default function Nav() {
  /**
   * State
   */

  const [shouldShowMenuModal, setShouldShowMenuModal] = useState(false);
  const [transitionStyles, setTransitionStyles] = useState<Record<string, any>>(
    transitionOpeningStyles
  );

  /**
   * Variables
   */

  const location = useLocation();
  const isIndexPath = location.pathname === '/';

  /**
   * Functions
   */

  function handleMenuModalClose(close: boolean) {
    // delay transition for closing
    if (close) {
      setShouldShowMenuModal(close);
      setTransitionStyles(transitionOpeningStyles);
    } else {
      setTransitionStyles(transitionClosingStyles);
      const closeMenu = setTimeout(() => setShouldShowMenuModal(close), 500);
      return () => clearTimeout(closeMenu);
    }
  }

  /**
   * Render
   */

  return (
    <>
      <Media query="(max-width: 62em)">
        {(matches: boolean) => (
          <div className={s['menu-container']}>
            <div
              className={`${s['menu-items']} org-menu-items ${
                matches ? s['is-active'] : ''
              } 
            
            `}>
              {/* NAV */}
              {!isIndexPath && (
                <nav role="navigation" id="navigation">
                  <ul className={`${s['menu']}`}>
                    <li tabIndex={0}>
                      <NavLink exact to="/create">
                        <span>Create</span>
                      </NavLink>
                    </li>
                    {/* <li tabIndex={0}>
                      <NavLink exact to="/mint">
                        <span>Mint</span>
                      </NavLink>
                    </li> */}
                  </ul>
                </nav>
              )}

              <div tabIndex={0} className={`${s['menu-modal-wrapper']}`}>
                <div
                  className={`${s['menu-modal-hamburger']} org-menu-modal-hamburger`}
                  aria-label="Menu"
                  aria-controls="navigation"
                  onClick={(event) => {
                    event.preventDefault();
                    handleMenuModalClose(true);
                  }}>
                  <HamburgerSVG />
                </div>
              </div>
              <div className={`${s['get-connected-button']}`}>
                <Web3ModalButton showWalletETHBadge />
              </div>
            </div>
          </div>
        )}
      </Media>
      {/** MODAL MENU */}
      <ReactModal
        ariaHideApp={false}
        className={`${m['modal-menu-content']}`}
        isOpen={shouldShowMenuModal}
        onRequestClose={() => {
          handleMenuModalClose(false);
        }}
        overlayClassName={`${m['modal-menu-overlay']} org-modal-menu-overlay`}
        role="dialog"
        style={
          {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
        }>
        <Transition appear in={shouldShowMenuModal} timeout={duration}>
          {(transition) => (
            <nav role="navigation" id="navigation">
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[transition],
                }}
                className={`${m['modal-menu-container']} org-modal-menu-container`}>
                <button
                  className={`${m['modal-menu-close']} org-modal-menu-close`}
                  onClick={(event) => {
                    event.preventDefault();
                    handleMenuModalClose(false);
                  }}>
                  <TimesSVG />
                </button>

                <div style={{margin: '4rem 0 2rem'}}>
                  <ModalLogo />
                </div>

                <div
                  className={`${s['modal-menu-connected-button']} org-modal-menu-connected-button`}>
                  <Web3ModalButton showWalletETHBadge />
                </div>
                <ul
                  className={`${s['menu']} ${m['modal-menu']} org-modal-menu`}>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <NavLink className="contribute" exact to="/create">
                      <span>🎨 Create</span>
                    </NavLink>
                  </li>
                  {/* <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <NavLink className="contribute" exact to="/mint">
                      <span>🎨 Mint</span>
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </nav>
          )}
        </Transition>
      </ReactModal>
    </>
  );
}
