import Web3 from 'web3';
import {Dispatch} from 'redux';

import {Web3State} from '../../util/enums';
import NFTFactory from '../../smart-contracts/NFTFactory.json';
import NFT from '../../smart-contracts/NFT.json';

export const BLOCKCHAIN_CONTRACTS = 'BLOCKCHAIN_CONTRACTS';
export const BLOCKCHAIN_WALLET_AUTHENTICATED =
  'BLOCKCHAIN_WALLET_AUTHENTICATED';
export const BLOCKCHAIN_WEB3_INSTANCE = 'BLOCKCHAIN_WEB3_INSTANCE';
export const BLOCKCHAIN_WEB3_STATE = 'BLOCKCHAIN_WEB3_STATE';
export const CONNECTED_ADDRESS = 'CONNECTED_ADDRESS';

export function initContractNFTFactory(web3Instance: Web3) {
  return async function (dispatch: Dispatch<any>) {
    try {
      if (web3Instance) {
        const networkId = await web3Instance.eth.net.getId();
        const nftFactoryContract: Record<string, any> = NFTFactory;
        const deployedNetwork: any = nftFactoryContract.networks[networkId];
        const contractAddress = deployedNetwork.address;
        const instance = new web3Instance.eth.Contract(
          nftFactoryContract.abi,
          contractAddress
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            NFTFactory: {
              abi: nftFactoryContract.abi,
              contractAddress,
              instance,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function initContractNFT(web3Instance: Web3) {
  return async function (dispatch: Dispatch<any>) {
    try {
      if (web3Instance) {
        const networkId = await web3Instance.eth.net.getId();
        const nftContract: Record<string, any> = NFT;
        const deployedNetwork: any = nftContract.networks[networkId];
        const contractAddress = deployedNetwork.address;
        const instance = new web3Instance.eth.Contract(
          nftContract.abi,
          contractAddress
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            NFT: {
              abi: nftContract.abi,
              contractAddress,
              instance,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function initWeb3Instance(instance: Web3) {
  return {
    type: BLOCKCHAIN_WEB3_INSTANCE,
    web3Instance: instance,
  };
}

/**
 * setConnectedAddress
 *
 * @param {string} selectedAddress
 *
 */
export function setConnectedAddress(selectedAddress: string | null) {
  return async function (dispatch: any) {
    dispatch({type: CONNECTED_ADDRESS, connectedAddress: selectedAddress});
    dispatch(
      selectedAddress
        ? web3State(Web3State.Connected)
        : web3State(Web3State.Locked)
    );
  };
}

export function walletAuthenticated(isAuthenticated: boolean) {
  return {
    type: BLOCKCHAIN_WALLET_AUTHENTICATED,
    walletAuthenticated: isAuthenticated,
  };
}

export function web3State(web3State: string) {
  return {
    type: BLOCKCHAIN_WEB3_STATE,
    web3State,
  };
}
