import {ContractSendType} from './types';
import {Web3TxStatus} from './enums';
import {
  ENVIRONMENT,
  PROXY_URL,
  PINATA_API_KEY,
  PINATA_API_SECRET,
} from '../util/config';

export const formatEthereumAddress = (addr: string, maxLength: number = 5) => {
  if (addr === null) return '---';

  if (typeof addr !== 'undefined' && addr.length > 9) {
    const firstSegment = addr.substring(0, maxLength);
    const secondPart = addr.substring(addr.length - 3);
    return firstSegment + '...' + secondPart;
  } else {
    return '---';
  }
};

/**
 * disableReactDevTools
 *
 * Run before the app mounts to disable React dev tools.
 * Ideally, this is run conditionally based on environment.
 *
 * @see: https://github.com/facebook/react-devtools/issues/191#issuecomment-443607190
 */
export function disableReactDevTools() {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
}

/**
 * getValidationError
 *
 * Used with react-hook-form (mostly to solve a TS incorrect behavior)
 * Gets the associated error message with a field.
 *
 * @param {string} field
 * @param {Record<string, any>} errors
 * @returns string
 */
export function getValidationError(
  field: string,
  errors: Record<string, any>
): string {
  return errors[field] && 'message' in errors[field]
    ? (errors[field].message as string)
    : '';
}

/**
 * contractSend
 *
 * Returns the resolved transaction receipt or error
 *
 * @param {any} contractInstance
 * @param {any} methodArguments
 * @param {string} methodName
 * @param {Record<string, any>} txArguments
 * @param {(txHash: string) => void} callback
 * @returns {Promise<ContractSendType>} Resolved transaction receipt or error
 */
export async function contractSend(
  methodName: string,
  contractInstance: any,
  methodArguments: any, // args passed as an array
  txArguments: Record<string, any>,
  callback: (txHash: string) => void // callback; return txHash
): Promise<ContractSendType> {
  return new Promise<ContractSendType>((resolve, reject) => {
    // estimate gas limit for transaction
    contractInstance[methodName](...methodArguments)
      .estimateGas({from: txArguments.from})
      .then((gas: number) => {
        contractInstance[methodName](...methodArguments)
          .send({
            ...txArguments,
            gas,
          })
          .on('transactionHash', function (txHash: string) {
            // return transaction hash
            callback(txHash);
          })
          .on('receipt', function (receipt: Record<string, any>) {
            // return transaction receipt; contains event returnValues
            resolve({
              receipt,
              txStatus: Web3TxStatus.FULFILLED,
            } as ContractSendType);
          })
          .on('error', (error: Error) => {
            // return transaction error
            reject({
              error,
              txStatus: Web3TxStatus.REJECTED,
            } as ContractSendType);
          });
      })
      .catch((error: Error) => {
        // return estimateGas error
        reject({
          error,
          txStatus: Web3TxStatus.REJECTED,
        } as ContractSendType);
      });
  });
}

/**
 * dontCloseWindowWarning
 *
 * Warns user not to close the window.
 *
 * @returns {() => void} unsubscribe function to stop listening, and the callback from firing.
 */
export function dontCloseWindowWarning(): () => void {
  // @see: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Example
  const callback = (event: BeforeUnloadEvent) => {
    // Cancel the event
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = '';
  };

  window.addEventListener('beforeunload', callback);

  return function unsubscribe() {
    window.removeEventListener('beforeunload', callback);
  };
}

/**
 * chooseRandom
 *
 * Choose a random item from an array.
 *
 * @param {array} array - The array to choose from.
 * @param doNotChooseItem - An item to not choose (e.g. previously chosen item)
 */
export function chooseRandom<T>(array: T[], doNotChooseItem?: T) {
  const arrayToUse =
    doNotChooseItem !== undefined
      ? array.filter((a) => a !== doNotChooseItem)
      : array;

  return arrayToUse[Math.floor(Math.random() * arrayToUse.length)];
}

/**
 * getFileType
 *
 * returns file type (e.g., image, video, audio)
 *
 * @param {File} file
 * @returns {string}
 */
export function getFileType(file: File): string {
  return file.type.split('/')[0];
}

/**
 * getTokenURIData
 *
 * Assumes `tokenURI` function in fetched ERC721 returns an HTTP or IPFS URL.
 * When queried, the URL should return JSON metadata (consistent with OpenSea
 * implementation
 * https://docs.opensea.io/docs/metadata-standards#section-implementing-token-uri).
 *
 * @param {string} tokenURI
 * @returns {Promise<Record<string, string>>}
 */
export async function getTokenURIData(
  tokenURI: string
): Promise<Record<string, string>> {
  try {
    // Handles IPFS URL in the format `ipfs://ipfs/<hash>` (as expected by
    // OpenSea for IPFS hosted metadata).
    const resolvingTokenURI = tokenURI.startsWith('ipfs://')
      ? tokenURI.replace('ipfs://', 'https://ipfs.io/')
      : tokenURI;

    const response = await fetch(PROXY_URL + resolvingTokenURI);
    return await response.json();
  } catch (error) {
    throw new Error(`Failed to fetch JSON data from tokenURI - ${error}`);
  }
}

/**
 * getFallbackTokenMetadata
 *
 * If a token does not use ERC721 contract and metadata standards it will likely
 * fail in the process above to fetch and parse the tokenURI. In that case, we
 * can try to retrieve the data using the OpenSea API.
 *
 * @param {string} contractAddress
 * @param {string} tokenId
 * @returns {Promise<Record<string, string>>}
 */
export async function getFallbackTokenMetadata(
  contractAddress: string,
  tokenId: string
): Promise<Record<string, string>> {
  try {
    const response = await fetch(
      `https://${
        ENVIRONMENT === 'production' ? '' : 'rinkeby-'
      }api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}`
    );
    return await response.json();
  } catch (error) {
    throw new Error('Failed to fetch JSON data from API');
  }
}

/**
 * getVideoCover
 *
 * If uploaded file is a video, capture an image from it to set as the cover
 * image (OpenSea uses image for preview card)
 *
 * @param {File} file
 * @param {number} [seekTo=0.0]
 */
function getVideoCover(file: File, seekTo: number = 0.0) {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.setAttribute('id', 'videoPlayer');
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      reject('error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx && ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx &&
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            'image/jpeg',
            0.9 /* quality */
          );
      });
    });
  });
}

/**
 * testPinataConnection
 *
 * Test connection to Pinata API
 *
 */
export function testPinataConnection(): void {
  fetch('https://api.pinata.cloud/data/testAuthentication', {
    method: 'GET',
    headers: {
      pinata_api_key: PINATA_API_KEY as string,
      pinata_secret_api_key: PINATA_API_SECRET as string,
    },
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.error) {
        throw new Error(result.error.details);
      }

      console.log(result.message);
    })
    .catch((error) => {
      console.error('Failed Pinata API connection:', error.message);
    });
}

/**
 * uploadFilesToIPFS
 *
 * Upload file(s) to Pinata IPFS node
 *
 * @param {File} file
 * @returns {Promise<{ fileUploadHash: string; secondaryFileUploadHash?: string }>}
 */
export async function uploadFilesToIPFS(
  file: File
): Promise<{fileUploadHash: string; secondaryFileUploadHash?: string}> {
  // file is prepared for upload to IPFS
  const formData = new FormData();
  formData.append('file', file);
  let metadataName = file.name;
  if (ENVIRONMENT !== 'production') {
    metadataName = metadataName + ' [dev]';
  }
  const metadata = JSON.stringify({
    name: metadataName,
  });
  formData.append('pinataMetadata', metadata);
  const fileResponse = await fetch(
    'https://api.pinata.cloud/pinning/pinFileToIPFS',
    {
      method: 'POST',
      body: formData,
      headers: {
        pinata_api_key: PINATA_API_KEY as string,
        pinata_secret_api_key: PINATA_API_SECRET as string,
      },
    }
  );
  const fileUploadResponse = await fileResponse.json();
  if (fileUploadResponse.error) {
    throw new Error('IPFS file upload error: ' + fileUploadResponse.error);
  }
  const fileUploadHash = fileUploadResponse.IpfsHash;
  console.log('IPFS file upload hash', fileUploadHash);

  // if file is a video, capture an image from it to set as the cover image
  let secondaryFileUploadHash = undefined;
  if (getFileType(file) === 'video') {
    const cover = await getVideoCover(file, 1.5);
    const secondaryFormData = new FormData();
    secondaryFormData.append('file', cover as Blob);
    let secondaryMetadataName = file.name + ' cover image';
    if (ENVIRONMENT !== 'production') {
      secondaryMetadataName = secondaryMetadataName + ' [dev]';
    }
    const secondaryMetadata = JSON.stringify({
      name: secondaryMetadataName,
    });
    secondaryFormData.append('pinataMetadata', secondaryMetadata);
    const secondaryFileResponse = await fetch(
      'https://api.pinata.cloud/pinning/pinFileToIPFS',
      {
        method: 'POST',
        body: secondaryFormData,
        headers: {
          pinata_api_key: PINATA_API_KEY as string,
          pinata_secret_api_key: PINATA_API_SECRET as string,
        },
      }
    );
    const secondaryFileUploadResponse = await secondaryFileResponse.json();
    if (secondaryFileUploadResponse.error) {
      throw new Error(
        'IPFS file upload error: ' + secondaryFileUploadResponse.error
      );
    }
    secondaryFileUploadHash = secondaryFileUploadResponse.IpfsHash;
    console.log('IPFS secondary file upload hash', secondaryFileUploadHash);
  }

  return {fileUploadHash, secondaryFileUploadHash};
}

/**
 * uploadJsonMetadataToIPFS
 *
 * JSON metadata is uploaded to Pinata IPFS node and returns hash to save as
 * part of `tokenURI` (this is what OpenSea reads to display token)
 *
 * @param {*} jsonBody
 * @returns {Promise<string>}
 */
export async function uploadJsonMetadataToIPFS(jsonBody: any): Promise<string> {
  if (ENVIRONMENT !== 'production') {
    jsonBody.pinataMetadata.name = jsonBody.pinataMetadata.name + ' [dev]';
  }
  const jsonResponse = await fetch(
    'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    {
      method: 'POST',
      body: JSON.stringify(jsonBody),
      headers: {
        pinata_api_key: PINATA_API_KEY as string,
        pinata_secret_api_key: PINATA_API_SECRET as string,
        'Content-Type': 'application/json',
      },
    }
  );
  const tokenMetadataUploadResponse = await jsonResponse.json();
  if (tokenMetadataUploadResponse.error) {
    throw new Error(
      'IPFS file upload error: ' + tokenMetadataUploadResponse.error
    );
  }
  const tokenMetadataUploadHash = tokenMetadataUploadResponse.IpfsHash;
  console.log('IPFS token metadata upload hash', tokenMetadataUploadHash);

  return tokenMetadataUploadHash;
}
