import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import {LeftLogo} from './logo';
import Nav from './nav';

import s from '../assets/scss/modules/header.module.scss';

export default function Header() {
  /**
   * Variables
   */

  const location = useLocation();
  const isIndexPath = location.pathname === '/';

  /**
   * Functions
   */

  // Render the location with or without a link depending on `location`
  function RenderLogo(props: React.PropsWithChildren<any>) {
    return isIndexPath ? props.children : <Link to="/">{props.children}</Link>;
  }

  return (
    <>
      <header className={s['header']}>
        <RenderLogo>
          <LeftLogo />
        </RenderLogo>

        <div className={s['page-nav']}>
          <Nav />
        </div>
      </header>
    </>
  );
}
