import React from 'react';

export default function WalletSVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.1395 22.0388C20.6922 22.0388 20.3291 21.6757 20.3291 21.2284V17.9869C20.3291 17.5396 20.6922 17.1765 21.1395 17.1765C21.5868 17.1765 21.9499 17.5396 21.9499 17.9869V21.2284C21.9499 21.6757 21.5868 22.0388 21.1395 22.0388Z"
        fill="currentColor"
      />
      <path
        d="M21.1395 13.9349C20.6922 13.9349 20.3291 13.5719 20.3291 13.1245V9.88301C20.3291 9.43568 20.6922 9.07263 21.1395 9.07263C21.5868 9.07263 21.9499 9.43568 21.9499 9.88301V13.1245C21.9499 13.5719 21.5868 13.9349 21.1395 13.9349Z"
        fill="currentColor"
      />
      <path
        d="M22.7602 13.935H18.7083C18.261 13.935 17.8979 13.5719 17.8979 13.1246C17.8979 12.6773 18.261 12.3142 18.7083 12.3142H22.7602C23.2076 12.3142 23.5706 12.6773 23.5706 13.1246C23.5706 13.5719 23.2076 13.935 22.7602 13.935Z"
        fill="currentColor"
      />
      <path
        d="M22.7602 18.7973H18.7083C18.261 18.7973 17.8979 18.4342 17.8979 17.9869C17.8979 17.5396 18.261 17.1765 18.7083 17.1765H22.7602C23.2076 17.1765 23.5706 17.5396 23.5706 17.9869C23.5706 18.4342 23.2076 18.7973 22.7602 18.7973Z"
        fill="currentColor"
      />
      <path
        d="M18.7083 18.7973C16.921 18.7973 15.4668 17.343 15.4668 15.5557C15.4668 13.7684 16.921 12.3142 18.7083 12.3142C19.1557 12.3142 19.5187 12.6773 19.5187 13.1246C19.5187 13.5719 19.1557 13.935 18.7083 13.935C17.8299 13.935 17.0876 14.6773 17.0876 15.5557C17.0876 16.4342 17.8299 17.1765 18.7083 17.1765C19.1557 17.1765 19.5187 17.5396 19.5187 17.9869C19.5187 18.4342 19.1557 18.7973 18.7083 18.7973Z"
        fill="currentColor"
      />
      <path
        d="M22.7601 18.7973C22.3128 18.7973 21.9497 18.4342 21.9497 17.9869V13.1246C21.9497 12.6773 22.3128 12.3142 22.7601 12.3142C23.2074 12.3142 23.5705 12.6773 23.5705 13.1246V17.9869C23.5705 18.4342 23.2074 18.7973 22.7601 18.7973Z"
        fill="currentColor"
      />
      <path
        d="M18.708 6.64151H4.93148C4.48414 6.64151 4.12109 6.27846 4.12109 5.83113C4.12109 5.3838 4.48414 5.02075 4.93148 5.02075H18.708C19.1553 5.02075 19.5183 5.3838 19.5183 5.83113C19.5183 6.27846 19.1553 6.64151 18.708 6.64151Z"
        fill="currentColor"
      />
      <path
        d="M20.3287 9.88309H4.93148C4.48414 9.88309 4.12109 9.52004 4.12109 9.07271C4.12109 8.62538 4.48414 8.26233 4.93148 8.26233H20.3287C20.7761 8.26233 21.1391 8.62538 21.1391 9.07271C21.1391 9.52004 20.7761 9.88309 20.3287 9.88309Z"
        fill="currentColor"
      />
      <path
        d="M20.3287 22.849H4.93148C4.48414 22.849 4.12109 22.486 4.12109 22.0387C4.12109 21.5913 4.48414 21.2283 4.93148 21.2283H20.3287C20.7761 21.2283 21.1391 21.5913 21.1391 22.0387C21.1391 22.486 20.7761 22.849 20.3287 22.849Z"
        fill="currentColor"
      />
      <path
        d="M20.3289 22.8491C19.8816 22.8491 19.5186 22.4861 19.5186 22.0387C19.5186 21.5914 19.8816 21.2284 20.3289 21.2284H20.3302C20.3302 20.781 20.6924 20.418 21.1397 20.418C21.5871 20.418 21.9497 20.781 21.9497 21.2284C21.9497 22.1218 21.2228 22.8491 20.3289 22.8491Z"
        fill="currentColor"
      />
      <path
        d="M19.5184 9.88308C19.0711 9.88308 18.708 9.52003 18.708 9.0727V6.64156C18.708 6.19423 19.0711 5.83118 19.5184 5.83118C19.9657 5.83118 20.3288 6.19423 20.3288 6.64156V9.0727C20.3288 9.52003 19.9657 9.88308 19.5184 9.88308Z"
        fill="currentColor"
      />
      <path
        d="M19.5187 7.45189C19.0714 7.45189 18.7083 7.08884 18.7083 6.64151V6.6407C18.261 6.6407 17.8979 6.27846 17.8979 5.83073C17.8979 5.3834 18.261 5.02075 18.7083 5.02075C19.6022 5.02075 20.3291 5.74766 20.3291 6.64151C20.3291 7.08884 19.966 7.45189 19.5187 7.45189V7.45189Z"
        fill="currentColor"
      />
      <path
        d="M21.1393 10.6935C20.692 10.6935 20.3289 10.3304 20.3289 9.88309V9.88228C19.8816 9.88228 19.5186 9.52004 19.5186 9.07231C19.5186 8.62497 19.8816 8.26233 20.3289 8.26233C21.2228 8.26233 21.9497 8.98924 21.9497 9.88309C21.9497 10.3304 21.5866 10.6935 21.1393 10.6935V10.6935Z"
        fill="currentColor"
      />
      <path
        d="M4.93163 22.8491C3.59086 22.8491 2.50049 21.7587 2.50049 20.4179C2.50049 19.9706 2.86354 19.6075 3.31087 19.6075C3.7582 19.6075 4.12125 19.9706 4.12125 20.4179C4.12125 20.8649 4.48471 21.2283 4.93163 21.2283C5.37896 21.2283 5.74201 21.5914 5.74201 22.0387C5.74201 22.486 5.37896 22.8491 4.93163 22.8491Z"
        fill="currentColor"
      />
      <path
        d="M3.31087 21.2283C2.86354 21.2283 2.50049 20.8653 2.50049 20.418V7.45186C2.50049 7.00453 2.86354 6.64148 3.31087 6.64148C3.7582 6.64148 4.12125 7.00453 4.12125 7.45186V20.418C4.12125 20.8653 3.7582 21.2283 3.31087 21.2283Z"
        fill="currentColor"
      />
      <path
        d="M4.93163 9.88304C3.59086 9.88304 2.50049 8.79267 2.50049 7.4519C2.50049 6.11112 3.59086 5.02075 4.93163 5.02075C5.37896 5.02075 5.74201 5.3838 5.74201 5.83113C5.74201 6.27846 5.37896 6.64151 4.93163 6.64151C4.49241 6.64151 4.12125 7.01267 4.12125 7.4519C4.12125 7.89112 4.49241 8.26228 4.93163 8.26228C5.37896 8.26228 5.74201 8.62533 5.74201 9.07266C5.74201 9.51999 5.37896 9.88304 4.93163 9.88304Z"
        fill="currentColor"
      />
    </svg>
  );
}
