import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';

import Wrap from '../common/Wrap';

import s from '../../assets/scss/modules/getstarted.module.scss';

export default function GetStarted() {
  /**
   * External hooks
   */

  const history = useHistory();

  /**
   * Effects
   */

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 40,
      delay: 120,
      mirror: false,
      once: true,
    });
  }, []);

  /**
   * Functions
   */

  function handleRoute(route: string) {
    history.push(`/${route}`);
  }

  /**
   * Render
   */

  return (
    <Wrap className={'section-wrapper'}>
      <div className={s['menu-container']}>
        <div className={s['menu-option']} onClick={() => handleRoute('create')}>
          <div className={s['menu-option__title']}>Create NFT</div>
          <div className={s['menu-option__icon']}>🎨</div>
          <div className={s['menu-option__description']}>
            Create your digital artwork with a new NFT contract.
          </div>
        </div>

        {/* <div className={s['menu-option']} onClick={() => handleRoute('mint')}>
          <div className={s['menu-option__title']}>Mint Token</div>
          <div className={s['menu-option__icon']}>🎨</div>
          <div className={s['menu-option__description']}>
            Mint your digital artwork to an existing NFT contract.
          </div>
        </div> */}
      </div>
    </Wrap>
  );
}
