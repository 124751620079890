import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {useHistory /* , Link */} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {
  uploadFilesToIPFS,
  uploadJsonMetadataToIPFS,
  contractSend,
  dontCloseWindowWarning,
  getFileType,
  getValidationError,
} from '../../util/helpers';
import {MetaMaskRPCError, StoreState, TokenURIMetadata} from '../../util/types';
import {CreateNFTStatus, Web3TxStatus} from '../../util/enums';
import {ETHERSCAN_URLS} from '../../util/config';
import {useETHGasPrice, useIsDefaultChain} from '../../hooks';
import {useWeb3Modal} from '../../components/web3/Web3ModalManager';
import Wrap from '../common/Wrap';
import FadeIn from '../common/FadeIn';
import CycleMessage from '../common/CycleMessage';
import FileDropzone from './FileDropzone';
import InputError from './InputError';
import {
  ACCEPTED_IMAGE_MIME_TYPES,
  ACCEPTED_VIDEO_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
  LARGE_FILE_INITIAL_MESSAGE,
} from './helper';
import Loader from '../feedback/Loader';
import ErrorMessageWithDetails from '../common/ErrorMessageWithDetails';

import b from '../../assets/scss/modules/buttons.module.scss';
import fs from '../../assets/scss/modules/formsteps.module.scss';
import i from '../../assets/scss/modules/input.module.scss';

enum Fields {
  file = 'file',
  tokenName = 'tokenName',
  symbol = 'symbol',
  tokenURIName = 'tokenURIName',
  description = 'description',
}

type FormInputs = {
  file: File;
  tokenName: string;
  symbol: string;
  tokenURIName: string;
  description?: string;
};

type CreateNFTArguments = [
  string[], // `owner`
  string, // `name`
  string, // `symbol`
  string, // `baseURI`
  number[], // `tokenId`
  string[] // `tokenURI`
];

// validation configuration for react-hook-form
const acceptedFileTypes = [
  ...ACCEPTED_IMAGE_MIME_TYPES,
  ...ACCEPTED_VIDEO_MIME_TYPES,
];
const ruleRequired = {
  required: ERROR_REQUIRED_FIELD,
};

const fileValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : file.size > MAX_FILE_SIZE
      ? ERROR_FILE_TOO_LARGE
      : !acceptedFileTypes.includes(file.type)
      ? 'The image is not the correct type. Please provide a jpg, .jpeg, .png, .gif, .webp, .svg, .mp4, or .webm file.'
      : true;
  },
};

const symbolValidate = {
  validate: (symbol: string) => {
    return !symbol
      ? ERROR_REQUIRED_FIELD
      : symbol.length > 5
      ? 'No more than 5 characters allowed.'
      : true;
  },
};

const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  file: fileValidate,
  tokenName: ruleRequired,
  symbol: symbolValidate,
  tokenURIName: ruleRequired,
};

export default function CreateNFT(): JSX.Element {
  /**
   * Selectors
   */

  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const NFTFactoryContract = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.NFTFactory
  );

  /**
   * Hooks
   */

  const {isDefaultChain, defaultChainError} = useIsDefaultChain();
  const {connected, account} = useWeb3Modal();
  const gasPrices = useETHGasPrice();

  /**
   * External hooks
   */

  const form = useForm<FormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const history = useHistory();

  /**
   * State
   */

  const [submitStatus, setSubmitStatus] = useState<CreateNFTStatus>(
    CreateNFTStatus.STANDBY
  );
  const [handleSubmitError, setHandleSubmitError] = useState<Error>();
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [uploadingFile, setUploadingFile] = useState<File>();

  /**
   * Variables
   */

  const {control, errors, formState, getValues, register, triggerValidation} =
    form;
  const isConnected = connected && account;
  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;
  const isInProcessOrDone =
    submitStatus === CreateNFTStatus.PENDING_UPLOAD ||
    submitStatus === CreateNFTStatus.AWAITING_CONFIRM ||
    submitStatus === CreateNFTStatus.PENDING ||
    submitStatus === CreateNFTStatus.FULFILLED ||
    isPromptOpen;

  /**
   * Effects
   */

  useEffect(
    () => () => {
      // Make sure to revoke the data URIs to avoid memory leaks
      const videoPlayer: any = document.getElementById('videoPlayer');
      videoPlayer && URL.revokeObjectURL(videoPlayer.src);
    },
    []
  );

  /**
   * Functions
   */

  function buildJsonMetadata(
    values: FormInputs,
    fileUploadHash: string,
    secondaryFileUploadHash?: string
  ): TokenURIMetadata {
    const {tokenURIName, description, file} = values;

    let tokenMetadata = {
      description,
      // This is the URL that will appear below the asset's image on OpenSea and
      // will allow users to leave OpenSea and view the item on our site (when
      // ready).
      // external_url: "",
      name: tokenURIName,
    };

    if (getFileType(file) === 'image') {
      tokenMetadata['image'] = `https://ipfs.io/ipfs/${fileUploadHash}`;
    }

    if (getFileType(file) === 'video') {
      tokenMetadata['animation_url'] = `https://ipfs.io/ipfs/${fileUploadHash}`;
      tokenMetadata[
        'image'
      ] = `https://ipfs.io/ipfs/${secondaryFileUploadHash}`;
    }

    return tokenMetadata;
  }

  function buildCreateNFTArguments(
    values: FormInputs,
    tokenMetadataUploadHash: string
  ): CreateNFTArguments {
    if (!account) {
      throw new Error(
        'No user account was found. Please make sure your wallet is connected.'
      );
    }

    const {tokenName, symbol} = values;

    const baseURI = 'ipfs://ipfs/';
    const tokenURI = tokenMetadataUploadHash;

    return [[account], tokenName, symbol, baseURI, [1], [tokenURI]];
  }

  async function handleSubmit(values: FormInputs) {
    try {
      if (!isConnected) {
        throw new Error(
          'No user account was found. Please makes sure your wallet is connected.'
        );
      }

      if (!isDefaultChain) {
        throw new Error(defaultChainError);
      }

      setSubmitStatus(CreateNFTStatus.PENDING_UPLOAD);
      const {file, tokenURIName} = values;

      // Upload file(s) to IPFS
      const {fileUploadHash, secondaryFileUploadHash} = await uploadFilesToIPFS(
        file
      );

      // JSON metadata is uploaded to IPFS and returns hash to save as part of
      // `tokenURI` (this is what OpenSea reads to display token)
      const jsonBody = {
        pinataMetadata: {
          name: `${tokenURIName} tokenURI metadata`,
        },
        pinataContent: buildJsonMetadata(
          values,
          fileUploadHash,
          secondaryFileUploadHash
        ),
      };
      const tokenMetadataUploadHash = await uploadJsonMetadataToIPFS(jsonBody);

      // smart contract call to create NFT
      setSubmitStatus(CreateNFTStatus.AWAITING_CONFIRM);
      // activate "don't close window" warning
      const unsubscribeDontCloseWindow = dontCloseWindowWarning();

      const handleProcessingTx = (txHash: string) => {
        if (!txHash) return;

        setIsPromptOpen(false);
        setSubmitStatus(CreateNFTStatus.PENDING);
        setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);
      };

      try {
        if (!NFTFactoryContract) {
          throw new Error('No NFTFactory contract.');
        }

        setHandleSubmitError(undefined);
        setEtherscanURL('');
        setIsPromptOpen(true);

        const txArguments = {
          from: account,
          to: NFTFactoryContract.contractAddress,
          // Set a fast gas price
          ...(gasPrices ? {gasPrice: gasPrices.fast} : null),
        };

        /**
         * NFTFactoryContract - createNFT
         *
         * Execute contract call for `createNFT`
         */
        contractSend(
          'createNFT',
          NFTFactoryContract.instance.methods,
          buildCreateNFTArguments(values, tokenMetadataUploadHash),
          txArguments,
          handleProcessingTx
        )
          .then(({txStatus, receipt, error}) => {
            if (txStatus === Web3TxStatus.FULFILLED) {
              if (!receipt) return;

              // Tx `receipt` resolved; tx went through.
              setSubmitStatus(CreateNFTStatus.FULFILLED);
              /**
               * Get `returnValues` from `receipt`.
               *
               * @see https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#id37
               */
              const {
                CreateNFT: {returnValues},
              } = receipt.events;

              // go to NFT Details page for newly created NFT
              setTimeout(
                () =>
                  history.push(`/nft/${returnValues.newNFT.toLowerCase()}/1`),
                2000
              );
            }

            if (txStatus === Web3TxStatus.REJECTED) {
              if (!error) return;
              setHandleSubmitError(error);
              setSubmitStatus(CreateNFTStatus.REJECTED);

              // If user closed modal (MetaMask error code 4001)
              // or via WalletConnect, which only provides a message and no code
              setIsPromptOpen(false);

              unsubscribeDontCloseWindow();
            }
          })
          .catch(({error}) => {
            setHandleSubmitError(error);
            setSubmitStatus(CreateNFTStatus.REJECTED);

            // If user closed modal (MetaMask error code 4001)
            // or via WalletConnect, which only provides a message and no code
            setIsPromptOpen(false);

            unsubscribeDontCloseWindow();
          });

        unsubscribeDontCloseWindow();
      } catch (error) {
        setIsPromptOpen(false);
        setHandleSubmitError(error);
        setSubmitStatus(CreateNFTStatus.REJECTED);

        unsubscribeDontCloseWindow();
      }
    } catch (error) {
      setHandleSubmitError(error);
      setSubmitStatus(CreateNFTStatus.REJECTED);
    }
  }

  function renderSubmitStatus() {
    switch (submitStatus) {
      case CreateNFTStatus.PENDING_UPLOAD:
        // Anticipate long delay for large files
        const longProcessExpected =
          uploadingFile &&
          uploadingFile?.size >
            20 * (1024 * 1024); /* bytes = n MB * (1024^2 bytes) */

        return (
          <CycleMessage
            intervalMs={longProcessExpected ? 4000 : 2000}
            messages={[
              'Uploading file\u2026',
              'Processing file\u2026',
              'Saving artwork\u2026',
              'Getting closer\u2026',
            ]}
            useFirstItemStart
            longProcessInitialMessage={
              longProcessExpected ? LARGE_FILE_INITIAL_MESSAGE : undefined
            }
            render={(message) => {
              return <FadeIn key={message}>{message}</FadeIn>;
            }}
          />
        );
      case CreateNFTStatus.AWAITING_CONFIRM:
        return 'Awaiting your confirmation\u2026';
      case CreateNFTStatus.PENDING:
        return (
          <>
            <CycleMessage
              intervalMs={2000}
              messages={[
                'Creating NFT\u2026',
                'Making collectible\u2026',
                'Minting token\u2026',
                'Getting closer\u2026',
              ]}
              useFirstItemStart
              render={(message) => {
                return <FadeIn key={message}>{message}</FadeIn>;
              }}
            />
            <small>
              <a href={etherscanURL} rel="noopener noreferrer" target="_blank">
                view progress
              </a>
            </small>
          </>
        );
      case CreateNFTStatus.FULFILLED:
        return (
          <>
            <div>NFT created!</div>
            <small>
              <a href={etherscanURL} rel="noopener noreferrer" target="_blank">
                view transaction
              </a>
            </small>
          </>
        );
      default:
        return;
    }
  }

  /**
   * Render
   */

  // Render wallet auth message if user is not connected
  if (!isConnected) {
    return (
      <RenderWrapper>
        <p
          className={`${fs['form-description']} color-yellow text-center org-notification info`}
          style={{paddingTop: 0}}>
          Connect your wallet to create your NFT.
        </p>
      </RenderWrapper>
    );
  }

  // Render wrong network message if user is on wrong network
  if (!isDefaultChain) {
    return (
      <RenderWrapper>
        <p
          className={`${fs['form-description']} color-yellow text-center org-notification info`}
          style={{paddingTop: 0}}>
          {defaultChainError}
        </p>
      </RenderWrapper>
    );
  }

  return (
    <RenderWrapper>
      <form className="org-verify-form" onSubmit={(e) => e.preventDefault()}>
        {/* ARTWORK UPLOAD */}
        <label className={`${i['label--column']} org-label--column`}>
          Upload Artwork
        </label>
        <small>
          Accepted file types: .jpg, .jpeg, .png, .gif, .webp, .svg, .mp4, .webm
          <br />
          Max file size: {`${MAX_FILE_SIZE_MB}MB`}
        </small>
        <Controller
          control={control}
          as={
            <FileDropzone
              acceptedTypes={acceptedFileTypes}
              aria-describedby="error-file"
              aria-invalid={errors.file ? 'true' : 'false'}
              // isFileClearable
              disabled={isInProcessOrDone}
            />
          }
          onChange={([event]: any) => {
            setUploadingFile(event.target.files[0]);
            return event.target.files[0];
          }}
          name={Fields.file}
          rules={validateConfig.file}
        />
        <InputError
          error={getValidationError(Fields.file, errors)}
          id="error-file"
        />

        {/* ARTWORK (TOKEN URI) NAME */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>Artwork Name</span>
          <input
            aria-describedby="error-tokenURIName"
            aria-invalid={errors.tokenURIName ? 'true' : 'false'}
            name={Fields.tokenURIName}
            ref={
              validateConfig.tokenURIName &&
              register(validateConfig.tokenURIName)
            }
            type="text"
            disabled={isInProcessOrDone}
          />
        </label>
        <InputError
          error={getValidationError(Fields.tokenURIName, errors)}
          id="error-tokenURIName"
        />

        {/* DESCRIPTION (optional) */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>
            Description <small>(optional)</small>
          </span>
          <textarea
            aria-describedby="error-description"
            aria-invalid={errors.description ? 'true' : 'false'}
            name={Fields.description}
            ref={register} // no validation for this optional field
            disabled={isInProcessOrDone}
          />
        </label>
        <InputError
          error={getValidationError(Fields.description, errors)}
          id="error-description"
        />

        {/* TOKEN NAME */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>Token Name</span>
          <input
            aria-describedby="error-tokenName"
            aria-invalid={errors.tokenName ? 'true' : 'false'}
            name={Fields.tokenName}
            ref={validateConfig.tokenName && register(validateConfig.tokenName)}
            type="text"
            disabled={isInProcessOrDone}
          />
        </label>
        <InputError
          error={getValidationError(Fields.tokenName, errors)}
          id="error-tokenName"
        />

        {/* SYMBOL */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>Token Symbol</span>
          <input
            aria-describedby="error-symbol"
            aria-invalid={errors.symbol ? 'true' : 'false'}
            name={Fields.symbol}
            ref={validateConfig.symbol && register(validateConfig.symbol)}
            type="text"
            disabled={isInProcessOrDone}
          />
        </label>
        <InputError
          error={getValidationError(Fields.symbol, errors)}
          id="error-symbol"
        />

        {/* SUBMIT */}
        <button
          style={{marginTop: '3.5rem'}}
          className={`${b.primary} org-primary-button`}
          disabled={isInProcessOrDone}
          onClick={() => {
            if (isInProcessOrDone) return;

            if (!isValid) {
              triggerValidation();
              return;
            }

            handleSubmit(getValues());
          }}
          type="submit">
          {submitStatus === CreateNFTStatus.PENDING ||
          submitStatus === CreateNFTStatus.PENDING_UPLOAD ||
          submitStatus === CreateNFTStatus.AWAITING_CONFIRM ? (
            <Loader />
          ) : submitStatus === CreateNFTStatus.FULFILLED ? (
            'Done'
          ) : (
            'Submit'
          )}
        </button>

        {/* SUBMIT STATUS */}
        <div className="org-submit-status-container">
          {isInProcessOrDone && renderSubmitStatus()}
        </div>

        {/* SUBMIT ERROR */}
        {handleSubmitError &&
          (handleSubmitError as MetaMaskRPCError).code !== 4001 && (
            <div className="text-center">
              <ErrorMessageWithDetails
                renderText="Something went wrong while creating the NFT."
                error={handleSubmitError}
              />
            </div>
          )}
      </form>
    </RenderWrapper>
  );
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  /**
   * Render
   */

  return (
    <>
      {/** REACT HELMET */}
      <Helmet>
        <title>Create NFT</title>
        <meta
          name="description"
          content="Create asset with a new NFT contract"
        />
      </Helmet>

      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">Create NFT</h2>
          </div>

          <div className="org-form-wrap">
            <div className={`${fs['content-wrap']}`}>
              <div
                className={`${fs['form-description']} org-form-description`}
                style={{paddingTop: 0}}>
                <p>
                  Create your digital artwork. The piece will be minted with a
                  newly created NFT contract and assigned to your account. Just
                  fill out the information below to create instantly!
                </p>

                {/* <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    marginTop: '2rem',
                  }}>
                  Looking to mint your piece as part of an existing NFT contract
                  instead? <Link to="/mint">Use this form.</Link>
                </p> */}
              </div>

              {/* RENDER CHILDREN */}
              {props.children}
            </div>
          </div>
        </FadeIn>
      </Wrap>
    </>
  );
}
