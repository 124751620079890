export const MAX_FILE_SIZE_MB: number = 750;
export const ERROR_FILE_TOO_LARGE: string = `The file is too large. Please provide a file of ${MAX_FILE_SIZE_MB}MB or less.`;
export const ERROR_REQUIRED_FIELD: string = 'This field is required.';
export const MAX_FILE_SIZE: number =
  MAX_FILE_SIZE_MB * (1024 * 1024); /* bytes = n MB * (1024^2 bytes) */
export const ACCEPTED_IMAGE_MIME_TYPES: string[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];
export const ACCEPTED_VIDEO_MIME_TYPES: string[] = ['video/mp4', 'video/webm'];
export const LARGE_FILE_INITIAL_MESSAGE: string =
  'Uploading large file\u2026\u0020Sit tight, this may take a few minutes.';
export const ERROR_INVALID_ETHEREUM_ADDRESS: string =
  'The ethereum address is invalid.';
