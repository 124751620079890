import {Redirect, Route, Switch} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Header from './components/header';
// import Footer from './components/footer';
import CreateNFT from './components/form/CreateNFT';
// import MintNFT from './components/form/MintNFT';
import NFTDetails from './components/nft/NFTDetails';
import NotFound from './components/NotFound';
import GetStarted from './components/start/GetStarted';

function App() {
  return (
    <>
      {/** REACT HELMET */}
      <Helmet>
        <title>NFT Minter</title>
        <meta name="description" content="Create NFTs" />
      </Helmet>

      {/** HEADER */}
      <Header />

      <main>
        <Switch>
          {[
            <Route key="splash" exact path="/" render={() => <GetStarted />} />,
            <Route
              key="create"
              exact
              path="/create"
              render={() => <CreateNFT />}
            />,
            // <Route key="mint" exact path="/mint" render={() => <MintNFT />} />,
            <Route
              key="nft-details"
              path="/nft/:ethereumAddress/:tokenId"
              render={() => <NFTDetails />}
            />,
            <Route key="notfound" path="/404" render={() => <NotFound />} />,
            <Route
              key="redirecttonotfound"
              render={() => <Redirect to="/404" />}
            />,
          ]}
        </Switch>
      </main>

      {/** FOOTER */}
      {/* <Footer /> */}
    </>
  );
}

export default App;
