import React from 'react';

import l from '../../assets/scss/modules/logo.module.scss';

interface LogoNFTProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoNFT(props: LogoNFTProps) {
  return (
    <div
      className={`${l['nft-minter']} ${
        props.size ? `${l[`nft-minter--${props.size}`]}` : ''
      }`}>
      NFT Minter
    </div>
  );
}
