import React, {useState, useEffect, ReactElement} from 'react';
import {useDropzone} from 'react-dropzone';

import {getFileType} from '../../util/helpers';
import s from '../../assets/scss/modules/filedropzone.module.scss';
import XCloseSVG from '../../assets/svg/XCloseSVG';

const noPreview = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type FileDropzoneProps = {
  acceptedTypes?: string | string[];
  isFileClearable?: boolean;
  onFilesChange?: (files: File[]) => void;
  renderClearButton?: (clearFunc: () => void) => ReactElement;
};

/**
 * FileDropzone
 *
 * Drag and drop component for files.
 * This component is integrated with react-hook-form.
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement>} props
 */
export default function FileDropzone(
  props: FileDropzoneProps & React.InputHTMLAttributes<HTMLInputElement>
) {
  const {
    acceptedTypes,
    isFileClearable,
    onChange,
    onFilesChange,
    renderClearButton,
    value,
    ...restProps
  } = props;

  const {onBlur} = restProps;

  const [userTouchedFileDialog, setUserTouchedFileDialog] = useState<
    'untouched' | 'touched'
  >('untouched');
  const [files, setFiles] = useState<File[]>([]);
  const [previewFiles, setPreviewFiles] = useState<File[]>([]);
  const onDrop = (acceptedFiles: File[]) => {
    onFilesChange && onFilesChange(acceptedFiles);
    // @TODO trigger the onChange function onDrop that the react-hook-form Controller
    // makes use of. Don't know if there's a better way than a fake Event object and typecasting?
    onChange &&
      onChange({
        target: {files: acceptedFiles as any},
      } as React.ChangeEvent<HTMLInputElement>);
    setFiles(acceptedFiles.map((f: File) => f));
    setPreviewFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const thumbs = previewFiles.map((file: any) => {
    const fileType = getFileType(file);

    if (fileType === 'image') {
      return (
        <div className={s['thumb']} key={file.name}>
          <img className={s['preview-file']} src={file.preview} alt="preview" />
        </div>
      );
    }

    if (fileType === 'video') {
      return (
        <div className={s['thumb']} key={file.name}>
          <video className={s['preview-file']} controls autoPlay>
            <source src={file.preview} />
          </video>
        </div>
      );
    }

    return (
      <div style={noPreview} key={file.name}>
        <small>No preview available</small>
      </div>
    );
  });
  const {getRootProps, getInputProps, isDragActive, isFileDialogActive} =
    useDropzone({
      accept: props.acceptedTypes,
      onDrop,
      onFileDialogCancel: handleFileDialogCancel,
      maxFiles: 1,
    });

  // set once so we know a user has opened the dialog and can
  // trigger an onBlur event
  if (userTouchedFileDialog === 'untouched' && isFileDialogActive) {
    setUserTouchedFileDialog('touched');
  }

  useEffect(() => {
    // @TODO look into using onFileDialogCancel from react-dropzone
    userTouchedFileDialog === 'touched' &&
      !isFileDialogActive &&
      onBlur &&
      onBlur({} as React.FocusEvent<HTMLInputElement>);
  }, [isFileDialogActive, userTouchedFileDialog, onBlur]);

  useEffect(
    () => () => {
      // Make sure to revoke the data URIs to avoid memory leaks
      previewFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [previewFiles]
  );

  function handleFileDialogCancel(index?: number) {
    if (isFileClearable && index === undefined) {
      onFilesChange && onFilesChange([]);
      setFiles([]);
      onChange && onChange([] as any);
    }

    if (isFileClearable && typeof index === 'number' && index >= 0) {
      const editedFiles = [...files];

      editedFiles.splice(index, 1);

      onFilesChange && onFilesChange(editedFiles);
      setFiles(editedFiles);
      onChange && onChange(editedFiles as any);
    }
  }

  return (
    <>
      <div className="org-file-preview-container">
        <div
          className={`${s.container} org-dropzone-container`}
          {...getRootProps()}>
          <input
            {...getInputProps({
              ...restProps,
              onChange,
            })}
          />

          {/* inner text of dropzone */}
          {isDragActive ? (
            <p
              className={`${s.text} org-filedropzone-text ${
                isDragActive
                  ? `${s['text--drag-active']} org-filedropzone-text--drag-active`
                  : ''
              }`}>
              Drop file here&hellip;
            </p>
          ) : (
            <p className={`${s.text} org-filedropzone-text`}>
              Drop file here or click to select
            </p>
          )}

          {/* file name list */}
          {files.length > 0 && (
            <ul className={`${s.list} org-filedropzone-list`}>
              {files.map((f, i) => (
                <li
                  className={`${s.listItem} org-filedropzone-listItem`}
                  key={f.name}>
                  {f.name}
                  {/* CLEAR BUTTON from USER */}
                  {isFileClearable &&
                    renderClearButton &&
                    renderClearButton(() => handleFileDialogCancel(i))}

                  {/* CLEAR BUTTON */}
                  {isFileClearable && !renderClearButton && (
                    <button
                      className={s['file-remove-button']}
                      onClick={() => handleFileDialogCancel(i)}
                      type="button">
                      <XCloseSVG
                        aria-labelledby={`remove-file-${f.name}`}
                        fill="white"
                      />
                      <span id={`remove-file-${f.name}`} className="hidden">
                        Remove
                      </span>
                    </button>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="org-preview">
          {previewFiles.length > 0 ? thumbs : <small>Preview</small>}
        </div>
      </div>
    </>
  );
}
