export enum Web3State {
  Connected = 'Wallet connected',
  Error = 'Error connecting to wallet',
  NoWallet = 'No wallet found',
  Locked = 'Wallet disconnected',
}

/**
 * @see https://chainid.network/
 */
export enum NetworkIDs {
  GOERLI = 5,
  KOVAN = 42,
  RINKEBY = 4,
  ROPSTEN = 3,
  MAINNET = 1,
}

/**
 * @see https://chainid.network/
 */
export enum NetworkNames {
  GOERLI = 'goerli',
  KOVAN = 'kovan',
  RINKEBY = 'rinkeby',
  ROPSTEN = 'ropsten',
  MAINNET = 'mainnet',
}

export enum FetchStatus {
  STANDBY = 'STANDBY',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum ConnectorNames {
  injected = 'injected',
  walletconnect = 'walletconnect',
}

export enum Web3TxStatus {
  STANDBY = 'STANDBY',
  AWAITING_CONFIRM = 'AWAITING_CONFIRM',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum CreateNFTStatus {
  STANDBY = 'STANDBY',
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  AWAITING_CONFIRM = 'AWAITING_CONFIRM',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}
